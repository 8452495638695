import React, { useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import {
  Toast,
  Buttons,
  Grid,
  List,
  Form,
  Svg,
  ModalConfirm,
  MainPageHeading,
  ManualImportTabs,
  StaticInputTable,
  ImportFile,
  AutoCompleteFilterSingleSelect,
} from '@/library/components';
import { InstrumentMasterList } from '../components';
import { lookupItems, lookupValue } from '@/library/utils';
import { useForm, Controller } from 'react-hook-form';
import { InstrumentMasterHoc } from '../hoc';
import { useStores } from '@/stores';
import { RouterFlow } from '@/flows';
import { toJS } from 'mobx';
import * as XLSX from 'xlsx';
import { AutoCompleteFilterSingleSelectMultiFieldsDisplay } from 'react-restyle-components';

const InstrumentMaster = InstrumentMasterHoc(
  observer(() => {
    const {
      loginStore,
      instrumentMasterStore,
      routerStore,
      labStore,
      departmentStore,
      interfaceManagerStore,
    } = useStores();
    const {
      control,
      handleSubmit,
      formState: { errors },
      setValue,
      reset,
    } = useForm();

    useEffect(() => {
      setValue('status', instrumentMasterStore.instrumentMaster.status);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instrumentMasterStore.instrumentMaster]);

    const [isImport, setIsImport] = useState<boolean>(false);
    const [arrImportRecords, setArrImportRecords] = useState<Array<any>>([]);
    const [modalConfirm, setModalConfirm] = useState<any>();
    const [isInputView, setIsInputView] = useState<boolean>(true);

    const handleFileUpload = (file: any) => {
      const reader = new FileReader();
      reader.addEventListener('load', (evt: any) => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { raw: true });
        const list = data.map((item: any) => {
          return {
            lab: item.Lab,
            department: item.Department,
            instType: item['Inst Type'],
            instId: item['Inst Id'],
            ipAddress: item['Ip Address'],
            portNo: Number.parseInt(item['Port No'] || 8080),
            enteredBy: loginStore.login?.userId,
            environment: loginStore.login?.environment,
            companyCode: loginStore.login?.companyCode,
            status: 'D',
          };
        });
        setArrImportRecords(list);
      });
      reader.readAsBinaryString(file);
    };

    const onSubmit = () => {
      instrumentMasterStore.instrumentMasterService
        .create({
          input: isImport
            ? { isImport, arrImportRecords }
            : {
                isImport,
                ...instrumentMasterStore.instrumentMaster,
              },
        })
        .then((response: any) => {
          if (response.createInstrumentMaster.success) {
            Toast.success({
              message: `😊 ${response.createInstrumentMaster.message}`,
            });
            reset();
            setIsInputView(!isInputView);
            setArrImportRecords([]);
            setIsImport(false);
            instrumentMasterStore.instrumentMasterService.list();
          }
        });
    };

    const tableView = useMemo(() => {
      return (
        <InstrumentMasterList
          data={instrumentMasterStore.instrumentMasterList || []}
          totalSize={instrumentMasterStore.instrumentMasterCount}
          extraData={{
            lab: labStore.listLabs?.filter((item) => item.status == 'A'),
            department: departmentStore?.listDepartment,
            instType: interfaceManagerStore.listInterfaceManager.filter(
              (item) => item.interfaceType === 'INSTRUMENT',
            ),
            lookupItems: routerStore.lookupItems,
          }}
          isView={RouterFlow.checkPermission(
            routerStore.userPermission,
            'View',
          )}
          isDelete={RouterFlow.checkPermission(
            routerStore.userPermission,
            'Delete',
          )}
          isUpdate={RouterFlow.checkPermission(
            routerStore.userPermission,
            'Update',
          )}
          isExport={RouterFlow.checkPermission(
            routerStore.userPermission,
            'Export',
          )}
          onDelete={(selectedUser) => setModalConfirm(selectedUser)}
          onSelectedRow={(rows) => {
            setModalConfirm({
              show: true,
              type: 'delete',
              id: rows,
              title: 'Are you sure?',
              body: 'Do you want to delete selected record?',
            });
          }}
          onUpdateItem={(value: any, dataField: string, id: string) => {
            setModalConfirm({
              show: true,
              type: 'update',
              data: { fields: { [dataField]: value }, id },
              title: 'Are you sure?',
              body: 'Do you want to update this record?',
            });
          }}
          onPageSizeChange={(page, limit) => {
            global.filter = { mode: 'pagination', page, limit };
          }}
          onFilter={(type, filter, page, limit) => {
            instrumentMasterStore.instrumentMasterService.filter({
              input: { type, filter, page, limit },
            });
            global.filter = { mode: 'filter', type, filter, page, limit };
          }}
          onApproval={async (records) => {
            setModalConfirm({
              show: true,
              type: 'update',
              data: { fields: { status: 'A' }, id: records._id },
              title: 'Are you sure?',
              body: 'Do you want to update this record?',
            });
          }}
        />
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instrumentMasterStore.instrumentMasterList]);

    return (
      <>
        <MainPageHeading
          title={routerStore.selectedComponents?.title || ''}
          store={loginStore}
        />
        <div
          className='flex justify-end'
          style={{
            position: 'fixed',
            right: '30px',
            top: '135px',
            zIndex: 9999,
          }}
        >
          {RouterFlow.checkPermission(routerStore.userPermission, 'Add') && (
            <Buttons.ButtonCircleAddRemoveBottom
              show={isInputView}
              onClick={() => setIsInputView(!isInputView)}
            />
          )}
        </div>
        <div className=' mx-auto  flex-wrap'>
          <div
            className={
              'p-2 rounded-lg shadow-xl ' + (isInputView ? 'hidden' : 'shown')
            }
          >
            <ManualImportTabs
              isImport={isImport}
              isImportDisable={
                !RouterFlow.checkPermission(
                  toJS(routerStore.userPermission),
                  'Import',
                )
              }
              onClick={(flag) => {
                setIsImport(flag);
              }}
            />
            {!isImport ? (
              <Grid cols={2}>
                <List direction='col' space={4} justify='stretch' fill>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Form.InputWrapper label='PLab' hasError={!!errors.lab}>
                        <AutoCompleteFilterSingleSelect
                          placeholder='Search by name'
                          disable={
                            loginStore.login &&
                            loginStore.login.role !== 'ADMINISTRATOR'
                              ? true
                              : false
                          }
                          data={{
                            list: labStore.listLabs?.filter(
                              (item) => item.status == 'A',
                            ),
                            displayKey: 'name',
                            findKey: 'name',
                          }}
                          displayValue={
                            instrumentMasterStore.instrumentMaster?.lab
                          }
                          hasError={!!errors.lab}
                          onFilter={(value: string) => {
                            labStore.LabService.filter({
                              input: {
                                type: 'filter',
                                filter: {
                                  name: value,
                                },
                                page: 0,
                                limit: 10,
                              },
                            });
                          }}
                          onSelect={(item) => {
                            onChange(item.name);
                            instrumentMasterStore.updateInstrumentMaster({
                              ...instrumentMasterStore.instrumentMaster,
                              lab: item.code,
                            });
                            labStore.updateLabList(labStore.listLabsCopy);
                          }}
                        />
                      </Form.InputWrapper>
                    )}
                    name='lab'
                    rules={{ required: true }}
                    defaultValue=''
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Form.InputWrapper
                        label='Department'
                        hasError={!!errors.department}
                      >
                        <AutoCompleteFilterSingleSelectMultiFieldsDisplay
                          placeholder='Search by code or name'
                          hasError={!!errors.department}
                          data={{
                            list: departmentStore?.listDepartment.filter(
                              (item) =>
                                item.lab ===
                                instrumentMasterStore.instrumentMaster.lab,
                            ),
                            displayKey: ['code', 'name'],
                          }}
                          displayValue={value}
                          onFilter={(value: string) => {
                            departmentStore.DepartmentService.filterByFields({
                              input: {
                                filter: {
                                  fields: ['code', 'name'],
                                  srText: value,
                                },
                                page: 0,
                                limit: 10,
                              },
                            });
                          }}
                          onSelect={(item) => {
                            onChange(item.code);
                            instrumentMasterStore.updateInstrumentMaster({
                              ...instrumentMasterStore.instrumentMaster,
                              department: item.code,
                            });
                            departmentStore.updateDepartmentList(
                              departmentStore.listDepartmentCopy,
                            );
                          }}
                        />
                      </Form.InputWrapper>
                    )}
                    name='department'
                    rules={{ required: true }}
                    defaultValue=''
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Form.InputWrapper
                        label='Inst Type'
                        hasError={!!errors.instType}
                      >
                        <AutoCompleteFilterSingleSelect
                          placeholder='Search by name'
                          disable={
                            loginStore.login &&
                            loginStore.login.role !== 'ADMINISTRATOR'
                              ? true
                              : false
                          }
                          data={{
                            list: interfaceManagerStore.listInterfaceManager.filter(
                              (item) => item.interfaceType === 'INSTRUMENT',
                            ),
                            displayKey: 'instrumentType',
                            findKey: 'instrumentType',
                          }}
                          displayValue={value}
                          hasError={!!errors.instrumentType}
                          onFilter={(value: string) => {
                            interfaceManagerStore.interfaceManagerService.filter(
                              {
                                input: {
                                  type: 'filter',
                                  filter: {
                                    instrumentType: value,
                                  },
                                  page: 0,
                                  limit: 10,
                                },
                              },
                            );
                          }}
                          onSelect={(item) => {
                            onChange(item.instrumentType);
                            instrumentMasterStore.updateInstrumentMaster({
                              ...instrumentMasterStore.instrumentMaster,
                              instType: item.instrumentType,
                            });
                          }}
                        />
                      </Form.InputWrapper>
                    )}
                    name='instType'
                    rules={{ required: false }}
                    defaultValue=''
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Form.Input
                        label='Inst Id'
                        placeholder='Inst Id'
                        value={value?.toString()}
                        onChange={(instId) => {
                          onChange(instId);
                          instrumentMasterStore.updateInstrumentMaster({
                            ...instrumentMasterStore.instrumentMaster,
                            instId,
                          });
                        }}
                      />
                    )}
                    name='instId'
                    rules={{ required: false }}
                    defaultValue=''
                  />
                </List>
                <List direction='col' space={4} justify='stretch' fill>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Form.Input
                        label='Ip Address'
                        placeholder='Ip Address'
                        value={value?.toString()}
                        onChange={(ipAddress) => {
                          onChange(ipAddress);
                          instrumentMasterStore.updateInstrumentMaster({
                            ...instrumentMasterStore.instrumentMaster,
                            ipAddress,
                          });
                        }}
                      />
                    )}
                    name='ipAddress'
                    rules={{ required: false }}
                    defaultValue=''
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Form.Input
                        label='Port No'
                        placeholder='Port No'
                        value={value}
                        type='number'
                        onChange={(portNo) => {
                          onChange(portNo);
                          instrumentMasterStore.updateInstrumentMaster({
                            ...instrumentMasterStore.instrumentMaster,
                            portNo: Number.parseInt(portNo),
                          });
                        }}
                      />
                    )}
                    name='portNo'
                    rules={{ required: false }}
                    defaultValue=''
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Form.Input
                        label='Entered By'
                        placeholder={
                          errors.enteredBy
                            ? 'Please Enter EnteredBy'
                            : 'EnteredBy'
                        }
                        disabled
                        hasError={!!errors.enteredBy}
                        value={loginStore.login?.userId}
                      />
                    )}
                    name='enteredBy'
                    rules={{ required: false }}
                    defaultValue=''
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Form.InputWrapper
                        label='Status'
                        hasError={!!errors.status}
                      >
                        <select
                          value={value}
                          className={`leading-4 p-2 focus:outline-none focus:ring block w-full shadow-sm sm:text-base border-2 ${
                            errors.status ? 'border-red  ' : 'border-gray-300'
                          } rounded-md`}
                          onChange={(e) => {
                            const status = e.target.value;
                            onChange(status);
                            instrumentMasterStore.updateInstrumentMaster({
                              ...instrumentMasterStore.instrumentMaster,
                              status,
                            });
                          }}
                        >
                          <option>Select</option>
                          {lookupItems(routerStore.lookupItems, 'STATUS').map(
                            (item: any, index: number) => (
                              <option key={index} value={item.code}>
                                {lookupValue(item)}
                              </option>
                            ),
                          )}
                        </select>
                      </Form.InputWrapper>
                    )}
                    name='status'
                    rules={{ required: true }}
                    defaultValue=''
                  />
                </List>
              </Grid>
            ) : (
              <>
                {arrImportRecords?.length > 0 ? (
                  <StaticInputTable data={arrImportRecords} />
                ) : (
                  <ImportFile
                    onClick={(file) => {
                      handleFileUpload(file[0]);
                    }}
                  />
                )}
              </>
            )}
            <br />
            <List direction='row' space={3} align='center'>
              <Buttons.Button
                size='medium'
                type='solid'
                icon={Svg.Save}
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </Buttons.Button>

              <Buttons.Button
                size='medium'
                type='outline'
                icon={Svg.Remove}
                onClick={() => {
                  window.location.reload();
                }}
              >
                Clear
              </Buttons.Button>
              <div className='clearfix' />
            </List>
          </div>
          <div className='p-2 rounded-lg shadow-xl overflow-scroll'>
            {tableView}
          </div>
          <ModalConfirm
            {...modalConfirm}
            click={(action) => {
              if (action === 'delete') {
                instrumentMasterStore.instrumentMasterService
                  .delete({
                    input: {
                      id: modalConfirm.id,
                    },
                  })
                  .then((response: any) => {
                    if (response.removeInstrumentMaster.success) {
                      Toast.success({
                        message: `😊 ${response.removeInstrumentMaster.message}`,
                      });
                      instrumentMasterStore.instrumentMasterService.list();
                    }
                  });
              } else if (action === 'update') {
                instrumentMasterStore.instrumentMasterService
                  .update({
                    input: {
                      ...modalConfirm.data.fields,
                      _id: modalConfirm.data.id,
                    },
                  })
                  .then((response: any) => {
                    if (response.updateInstrumentMaster.success) {
                      Toast.success({
                        message: `😊 ${response.updateInstrumentMaster.message}`,
                      });
                      instrumentMasterStore.instrumentMasterService.list();
                    }
                  });
              }
              setModalConfirm({ show: false });
            }}
            close={() => {
              setModalConfirm({ show: false });
            }}
          />
        </div>
      </>
    );
  }),
);

export default InstrumentMaster;
